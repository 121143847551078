import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterOption", "filterCounter"]
  static values = { filterCount: 0 }

  connect() {
    this.countAndSet();
  }

  toggleFilterOption() {
    this.countAndSet();
    this.filterOptionTarget.form.requestSubmit();
  }

  countAndSet() {
    const count = this.filterOptionTargets.reduce((prevValue, target) => {
      return prevValue + (target.checked ? 1 : 0)
    }, 0);
    this.filterCounterTarget.innerHTML = `${count} selected`;
    if (count > 0) {
      this.filterCounterTarget.classList.remove("hidden");
    } else {
      this.filterCounterTarget.classList.add("hidden");
    }
  }
}
